<template>
  <div class="px-10 py-8">
    <!-- <div
      class="mt-5 d-flex align-center justify-center"
      v-if="loadingGetCities"
    >
      <v-progress-circular color="grey" indeterminate class="text-center" />
      <div class="grey--text ml-2 font-weight-bold">載入縣市...</div>
    </div>
    <v-select
      v-model="cityId"
      :items="cities"
      label="選擇縣市"
      hide-details
      class="mb-4"
      solo
      item-text="name"
      item-value="id"
      v-else
    ></v-select> -->
    <v-row class="font-weight-bold">
      <v-col cols="12" md="2">
        <v-card class="font-weight-bold">
          <div class="text-center pt-5">總表連線率</div>
          <div class="px-10 pb-10 pt-5 d-flex justify-center align-center">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="12" class="text-center">
                <v-progress-circular
                  :rotate="270"
                  :size="120"
                  :width="15"
                  :value="connectedSchoolDeviceRate"
                  color="primary"
                  class="text-h5"
                >
                  {{ connectedSchoolDeviceRate }}%
                </v-progress-circular>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="10"
                :class="{ 'mt-3': !$vuetify.breakpoint.xs }"
                class="accent--text"
              >
                <div class="d-flex justify-space-between">
                  <div>總表連線數</div>
                  <div>{{ connectedSchoolDeviceAmount }}</div>
                </div>
                <div class="mt-2 d-flex justify-space-between">
                  <div>總表數</div>
                  <div>{{ schoolDeviceAmount }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="font-weight-bold mt-5">
          <div class="text-center pt-5">教室連線率</div>
          <div class="px-10 pb-10 pt-5 d-flex justify-center align-center">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="12" class="text-center">
                <v-progress-circular
                  :rotate="270"
                  :size="120"
                  :width="15"
                  :value="connectedClassDeviceRate"
                  color="primary"
                  class="text-h5"
                >
                  {{ connectedClassDeviceRate }}%
                </v-progress-circular>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="10"
                :class="{ 'mt-3': !$vuetify.breakpoint.xs }"
                class="accent--text"
              >
                <div class="d-flex justify-space-between">
                  <div>教室連線數</div>
                  <div>{{ connectedClassDeviceAmount }}</div>
                </div>
                <div class="mt-2 d-flex justify-space-between">
                  <div>教室數</div>
                  <div>{{ schoolClassAmount }}</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-card class="pa-5" width="100%">
          <div class="d-flex">
            <div class="text-h6 accent--text font-weight-bold">
              {{ theCity.name }}學校列表
            </div>
            <v-spacer></v-spacer>
            <v-btn
              @click="$router.push({ name: 'ClassroomConnectionRate' })"
              color="primary"
              outlined
              >行政院版本 <v-icon small>mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="mx-4"
              @click="download"
              :loading="loadingDownload"
              >下載報表 <v-icon small class="ml-2">mdi-download</v-icon>
            </v-btn>
          </div>
          <v-col cols="12" sm="6" class="ml-auto">
            <v-text-field
              v-model="searchWords"
              placeholder="搜尋名稱"
              filled
              rounded
              dense
              append-icon="mdi-magnify"
              hide-details=""
              class="text-right mb-2"
            />
          </v-col>
          <div class="text-center mt-10" v-if="loadingSchools">
            <v-progress-circular color="grey" indeterminate class="mx-auto" />
          </div>
          <v-data-table
            :headers="headers"
            :items="filterSchools"
            hide-default-footer
            :items-per-page="-1"
            :loading="loadingSchoolDevices"
            loading-text="載入中..."
            no-data-text="( 無資料 )"
          >
            <template v-slot:[`item.classroomConnectionRate`]="{ item }">
              {{ item.classroomConnectionRate + '%' }}
            </template>
            <template v-slot:[`item.schoolMeterConnectionRate`]="{ item }">
              {{ item.schoolMeterConnectionRate + '%' }}
            </template>
            <!-- <template v-slot:[`item.classConnectedCount`]="{ item }">
              {{ (item.child && item.child.length) || 0 }}
            </template>
            <template v-slot:[`item.classConnectedCount`]="{ item }">
              {{ classConnectedCount(item) }}
            </template>
            <template v-slot:[`item.classConnectedRate`]="{ item }">
              {{ classConnectedRate(item) }}
            </template>
            <template v-slot:[`item.schoolDeviceCount`]="{ item }">
              {{ schoolDevicesAmount(item) }}
            </template>
            <template v-slot:[`item.schoolDeviceConnectedCount`]="{ item }">
              {{ schoolDeviceConnectedCount(item) }}
            </template>
            <template v-slot:[`item.schoolDeviceConnectedRate`]="{ item }">
              {{ schoolDeviceConnectedRate(item) }}
            </template> -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import City from '@/api/admin/City'
import School from '@/api/admin/School'
import Connection from '@/api/admin/Connection'

export default {
  data() {
    return {
      theCity: {},
      schools: [],
      loadingGetCities: false,
      loadingSchools: false,
      loadingSchoolDevices: false,
      loadingDownload: false,
      headers: [
        { text: '學校名稱', value: 'schoolName' },
        { text: '教室數', value: 'classroomCount', align: 'center' },
        {
          text: '教室連線數',
          value: 'classroomConnectionCount',
          align: 'center'
        },
        {
          text: '教室連線率',
          value: 'classroomConnectionRate',
          align: 'center'
        },
        { text: '總表數', value: 'schoolMeterCount', align: 'center' },
        {
          text: '總表數連線數',
          value: 'schoolMeterConnectionCount',
          align: 'center'
        },
        {
          text: '總表數連線率',
          value: 'schoolMeterConnectionRate',
          align: 'center'
        }
      ],
      searchWords: ''
    }
  },
  computed: {
    connectedSchoolDeviceAmount() {
      return this.schools.reduce((acc, cur) => {
        return acc + cur.schoolMeterConnectionCount
      }, 0)
    },
    schoolDeviceAmount() {
      return this.schools.reduce((acc, cur) => {
        return acc + cur.schoolMeterCount
      }, 0)
    },
    connectedSchoolDeviceRate() {
      return (
        Math.ceil(
          (this.connectedSchoolDeviceAmount / this.schoolDeviceAmount) * 10000
        ) / 100
      )
    },
    connectedClassDeviceAmount() {
      return this.schools.reduce((acc, cur) => {
        return acc + cur.classroomConnectionCount
      }, 0)
    },
    schoolClassAmount() {
      return this.schools.reduce((acc, cur) => {
        return acc + cur.classroomCount
      }, 0)
    },
    connectedClassDeviceRate() {
      return (
        Math.ceil(
          (this.connectedClassDeviceAmount / this.schoolClassAmount) * 10000
        ) / 100
      )
    },
    filterSchools() {
      let filterSchools = this.schools.filter((school) =>
        school.schoolName.includes(this.searchWords)
      )
      return filterSchools
    }
  },
  async mounted() {
    await this.getCities()
    await this.getConnectionRate()
  },
  methods: {
    async getCities() {
      try {
        const { data } = await City.list()
        if (data.length === 1) this.theCity = data[0]
      } catch (error) {
        console.error(error)
      }
    },
    async getConnectionRate() {
      try {
        const { data } = await Connection.list()
        this.schools = data
      } catch (error) {
        console.error(error)
      }
    },
    async download() {
      try {
        let fileType = 'csv'
        this.loadingDownload = true
        const { data } = await Connection.download(fileType)
        const url = window.URL.createObjectURL(
          new Blob([(data ? '\ufeff' : '') + data], {
            type: 'text/csv;charset=utf-8;'
          })
        )
        const link = document.createElement('a')
        link.href = url
        let fileName = this.getResponseFilename(fileType)
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingDownload = false
      }
    },
    getResponseFilename(fileType) {
      const date = moment().format('YYYY-MM-DD_HH-mm-ss')
      return (
        (this.theCity.name ? `${this.theCity.name}_` : '') +
        `連線率_${date}.${fileType}`
      )
    }

    // async init() {
    //   await this.getSchools()
    // },
    // async getSchools() {
    //   this.loadingSchoolDevices = true
    //   try {
    //     this.schools = []
    //     const { data } = await School.list(this.cityId)
    //     let citySchools = data.filter((school) => school.cityId === this.cityId)
    //     for (let index = 0; index < citySchools.length; index++) {
    //       try {
    //         const { data } = await Device.getSchoolDeviceStatus(
    //           citySchools[index].id
    //         )
    //         this.schools = this.schools.concat(data)
    //       } catch (error) {
    //         console.error(error)
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error)
    //   } finally {
    //     this.loadingSchoolDevices = false
    //   }
    // },
    // schoolDevicesAmount(school) {
    //   return school.devices.filter(({ name }) => !name.includes('中央儲值'))
    //     .length
    // },
    // classConnectedCount(school) {
    //   if (!school.child) return 0
    //   return school.child?.reduce((acc, cur) => {
    //     return (
    //       acc +
    //       (cur.devices?.filter(({ updateTs }) => !updateTs).length ||
    //       cur.devices.length === 0
    //         ? 0
    //         : 1)
    //     )
    //   }, 0)
    // },
    // classConnectedRate(school) {
    //   if (!school.child?.length) return '0%'
    //   else
    //     return `${
    //       Math.ceil(
    //         (this.classConnectedCount(school) / school.child?.length) * 10000
    //       ) / 100
    //     }%`
    // },
    // schoolDeviceConnectedCount(school) {
    //   return school.devices?.filter(
    //     ({ name, updateTs }) => !name.includes('中央儲值') && updateTs
    //   ).length
    // },
    // schoolDeviceConnectedRate(school) {
    //   if (!this.schoolDevicesAmount(school)) return '0%'
    //   else
    //     return `${
    //       Math.ceil(
    //         (this.schoolDeviceConnectedCount(school) /
    //           this.schoolDevicesAmount(school)) *
    //           10000
    //       ) / 100
    //     }%`
    // }
  }
}
</script>
